import IsAuthorized from './isAuthorized';
export function isAuthorizedList(list) {
    return list
        .filter((el) => (el.permissionString && IsAuthorized(el.permissionString)) || el?.children)
        .map((item) => {
        if (item.children) {
            item.children = isAuthorizedList(item.children);
        }
        return item;
    })
        .filter((item) => {
        if (!item.permissionString) {
            return item.children && item.children.length > 0;
        }
        return item.permissionString && IsAuthorized(item.permissionString);
    });
}
